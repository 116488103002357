// import flappierBird_flies_away from "./flappierBird_flies_away.gif";
import birdup_full from "./birdup_full.PNG";
import flappierBird_flies_away from "./flappierBird_flies_away.gif";
/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Kirk Boyd",
  description:
    "This is a website to showcase my projects and accomplishments (so far)",
  og: {
    title: "Kirk  Boyd",
    type: "website",
    url: "http://kirkboyd.xyz/",
  },
};

//Home Page
const greeting = {
  title: "Kirk Boyd",
  logo_name: "Kirk Boyd",
  //nickname: "KirkBoyd",
  subTitle:
    "This is a website to showcase my projects and accomplishments (so far)",
  resumeLink:
    "https://drive.google.com/file/d/1mYSBHrXR7OQkHk322HopyozadA0VutOF/view?usp=sharing",
  portfolio_repository: "https://github.com/ashutosh1919/masterPortfolio",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/KirkBoyd",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/kirklandmboyd/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
];

const skills = {
  data: [
    {
      title: "First Autonomous Flight!",
      fileName: "flappierBird_flies_away",
      path: flappierBird_flies_away,
      skills: [""],
      softwareSkills: [
        // {
        //   skillName: "Python",
        //   fontAwesomeClassname: "ion-logo-python",
        //   style: {
        //     backgroundColor: "transparent",
        //     color: "#3776AB",
        //   },
        // },
      ],
    },
    {
      title: "",
      fileName: "birdup_full",
      path: birdup_full,
      skills: [
        "Please note, this is a work in progress, and I am learning as I develop the site. If you are interested, check back occasionally as I will continue to improve it!",
      ],
      softwareSkills: [],
    },
  ],
};

// Education Page
// const competitiveSites = {
// //   competitiveSites: [
// //     {
// //       siteName: "HackerRank",
// //       iconifyClassname: "simple-icons:hackerrank",
// //       style: {
// //         color: "#2EC866",
// //       },
// //       profileLink: "https://www.hackerrank.com/layman_brother",
// //     },
// //   ],
// };

const degrees = {
  degrees: [
    {
      title: "University of Colorado Boulder - Boulder, CO",
      subtitle: "M.S. in Mechanical Engineering (Design Focus)",
      logo_path: "boulder_centered.jpg",
      alt_name: "CU Boulder",
      duration: "2021 - 2023 (Expected)",
      descriptions: [
        "Projects focusing on robotics and design",
        "GPA: 3.75/4.0",
      ],
      website_link: "https://www.colorado.edu/engineering/",
    },
    {
      title: "Trinity College - Hartford, CT",
      subtitle: "B.S. in Engineering (Mechanical Focus) and B.S. in Physics",
      logo_path: "trin_logo.png",
      alt_name: "Trinity",
      duration: "2017 - 2021",
      descriptions: ["Kelter Scholar", "GPA: 3.299/4.0"],
      website_link: "http://trincoll.edu",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "FE Mechanical",
      subtitle: "Pass",
      logo_path: "ncees_screenshot_cropped.png",
      certificate_link: "https://account.ncees.org/rn/2214720-1429572-824e30c",
      alt_name: "FE Exam",
      color_code: "#2A73CC",
    },
    {
      title: "CodeAcademy",
      subtitle: "JavaScript",
      logo_path: "codeAcademy_logo.png",
      certificate_link: "https://www.codecademy.com/profiles/KirkBoyd",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  //subtitle: "Work/Internships",
  description:
    "I have worked thus far as a Teaching Assistant, Student Mentor, and as a Private Contractor. In the future, I am hoping to gain experience in industry with either robotics or mechanical engineering design.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Physics TA",
          company: "Trinity College",
          company_url: "https://trincoll.edu",
          logo_path: "trin_logo.png",
          duration: "Fall 2019 - Fall 2020",
          location: "Hartford, CT",
          description:
            "Worked as a teaching assistant (TA) for several physics classes including introductory mechanics (Calculus-based and Algebra-based), electricity & magnetism, and optics & modern physics.",
          color: "#0879bf",
        },
        {
          title:
            "TA - Engineering 120 (Introduction to Engineering Design - Mobile Robots)",
          company: "Trinity College",
          company_url: "https://trincoll.edu",
          logo_path: "bant.jpg",
          duration: "Spring 2019",
          location: "Hartford",
          description:
            "Teaching assistant for introductory mobile robotics course where students build Arduino-based autonomous robots to navigate a maze and extinguish a flame.",
          color: "#9b1578",
        },
        {
          title:
            "Lead Mentor - Engineering 120 (Introduction to Engineering Design - Mobile Robots)",
          company: "Trinity College",
          company_url: "https://trincoll.edu",
          logo_path: "bant.jpg",
          duration: "Spring 2021",
          location: "Hartford",
          description:
            "Student mentor for aforementioned class. Rather than assisting with workshops, mentored a specific group on the construction of their robot.",
          color: "#9b1578",
        },
      ],
    },
    {
      title: "Clubs / Extracurriculars / Professional Societies",
      experiences: [
        {
          title: "Robot Study Team - President",
          company: "Trinity College Robotics",
          company_url: "https://trincoll.edu",
          logo_path: "trin_logo.png",
          duration: "Spring 2018 - Spring 2021",
          location: "Hartford, CT",
          description:
            "Became President in the fall of 2019 after serving as a member beginning in the spring of 2019.",
          color: "#4285F4",
        },
        {
          title: "Founding Member",
          company: "IEEE RAS",
          company_url: "https://www.ieee-ras.org/",
          logo_path: "ieee-ras-logo.png",
          duration: "Spring 2019 - Fall 2021",
          location: "Hartford, CT",
          description:
            "Founding member of the Trinity College Institute of Electrical and Electronics Engineers (IEEE) Robotics and Automation Society (RAS) student chapter.",
          color: "#D83B01",
        },
        {
          title: "Member",
          company: "IEEE",
          company_url: "https://www.ieee.org/",
          logo_path: "ieee.gif", //"trin_logo.png", //"ieee.gif",
          duration: "Aug 2019 - May 2021",
          location: "Hartford, CT",
          description:
            "Member of the Trinity College Institute of Electrical and Electronics Engineers (IEEE) student chapter.",
          color: "#D83B01",
        },
        {
          title: "Student Member",
          company: "SPS",
          company_url: "https://www.spsnational.org/",
          logo_path: "sps_cropped.jpg", //"trin_logo.png", //"sps-sigma-combined-long.png",
          duration: "Oct 2019 - May 2020",
          location: "Hartford, CT",
          description:
            "Member of the Trinity College Society of Physics Students (SPS) student chapter.",
          color: "#000000",
        },
        {
          title: "Student Member",
          company: "ASME",
          company_url: "https://www.asme.org/",
          logo_path: "asme.png",
          duration: "Fall 2019 - Spring 2021",
          location: "Hartford, CT",
          description:
            "Member of the Trinity College American Society of Mechanical Engineers (ASME) student chapter.",
          color: "#0C9D58",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description: "Below are some of my most noteworthy coding projects.",
  //avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "kb_logo.png",
    description: "The best way to reach me is by email or LinkedIn",
  },
  blogSection: {
    title: "Email",
    subtitle: "",
    link: "kirk.boyd@colorado.edu",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Where am I now?",
    subtitle: "Likely working on my next robot at CU!",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/DfeBSJxo6FBQUQbL9",
  },
  // phoneSection: {
  //   title: "Phone Number",
  //   subtitle: "+91 8320758513",
  // },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  //competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};
